import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../helpers/intlMessages";
export default function ClientConfirmation(props) {
    return (
        <SweetAlert
            show={props.show}
            warning
            showCancel
            confirmBtnText="Oui"
            cancelBtnText={<IntlMessages id="action.common.cancel"/>}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={<IntlMessages id="leave.edit.confirmation"/>}
            onConfirm={() => props.onConfirm()}
            onCancel={() => props.onCancel()}
        >
            <IntlMessages id="leave.edit.confirmation.text"/>
        </SweetAlert>
    );
}
